<template>
  <v-app>
    <TopToolbar v-if="isAuthenticated" />
    <v-main>
      <router-view />
    </v-main>
    <BottomNav v-if="isAuthenticated" />
  </v-app>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import BottomNav from '@/components/BottomNav.vue'
import TopToolbar from '@/components/TopToolbar.vue'
import { useUserStore } from '@/stores/user'

const userStore = useUserStore()
const isAuthenticated = computed(() => userStore.state.value === 'authenticated')
</script>

<style>
.v-main {
  padding-bottom: 56px !important;
}

/* Убираем нижний отступ, если пользователь не авторизован */
:deep(.v-main:has(+ .v-bottom-navigation)) {
  padding-bottom: 56px !important;
}
:deep(.v-main:not(:has(+ .v-bottom-navigation))) {
  padding-bottom: 0 !important;
}
</style>
