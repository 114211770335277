import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "home-screen" }
const _hoisted_2 = { key: 0 }

import { watch } from 'vue'
import { useRouter } from 'vue-router'
import { useUserStore } from '@/stores/user'
import SignInForm from "@/components/SignInForm.vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'Home',
  setup(__props) {

const router = useRouter()
const userStore = useUserStore()

watch(() => userStore.state.value, (state) => {
  if (state === 'authenticated') {
    router.push('/overview')
  }
}, { immediate: true })

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_unref(userStore).state.value === 'starting')
      ? (_openBlock(), _createElementBlock("progress", _hoisted_2))
      : _createCommentVNode("", true),
    _withDirectives(_createVNode(SignInForm, null, null, 512), [
      [_vShow, _unref(userStore).state.value === 'no_user']
    ])
  ]))
}
}

})