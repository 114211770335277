<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>Смена тарифа</v-card-title>
          <v-card-subtitle>
            Вы можете подать заявку на смену тарифа
          </v-card-subtitle>
          
          <v-card-text>
            <v-select
              v-model="selectedTariff"
              :items="tariffs"
              item-title="text"
              item-value="value"
              label="Выберите новый тариф"
              variant="outlined"
              return-object
            />
          </v-card-text>

          <v-card-actions>
            <v-btn 
              color="primary"
              :disabled="!selectedTariff"
              @click="submitTariffChange"
            >
              Подать заявку
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col cols="12">
        <v-card>
          <v-card-title>Роуминг</v-card-title>
          <v-card-subtitle>
            Управление роумингом
          </v-card-subtitle>
          
          <v-card-text>
            <v-switch
              v-model="roamingEnabled"
              label="Включить роуминг"
              color="primary"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import axios from 'axios'
import { useUserStore } from '@/stores/user'

interface Tariff {
  id: number
  Name: string
  group: string
}

const userStore = useUserStore()
const selectedTariff = ref(null)
const tariffs = ref([])
const roamingEnabled = ref(false)

const fetchTariffs = async () => {
  try {
    const response = await axios.get('https://admin.montelcompany.me/api/tariffs')
    const items = response.data
    tariffs.value = items
      .filter((t: Tariff) => t.group === '1')
      .map((item: Tariff) => ({
        text: item.Name,
        value: item.id
      }))
  } catch (error) {
    console.error('Error fetching tariffs:', error)
  }
}

const submitTariffChange = async () => {
  if (!selectedTariff.value) return
  
  try {
    // Здесь будет логика отправки запроса на смену тарифа
    console.log('Submitting tariff change:', selectedTariff.value)
  } catch (error) {
    console.error('Error submitting tariff change:', error)
  }
}

watch(() => userStore.state.value, async (state) => {
  if (state === 'authenticated') {
    await fetchTariffs()
  }
}, { immediate: true })
</script>

<style scoped>
.services-container {
  padding-bottom: 56px !important;
  margin-bottom: 56px !important;
}

.v-card {
  margin-bottom: 16px;
}

.headline {
  font-size: 1.25rem !important;
  font-weight: 500;
}
</style>
