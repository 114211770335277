import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { ref, watch } from 'vue'
import axios from 'axios'
import { useUserStore } from '@/stores/user'

interface Tariff {
  id: number
  Name: string
  group: string
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyServices',
  setup(__props) {

const userStore = useUserStore()
const selectedTariff = ref(null)
const tariffs = ref([])
const roamingEnabled = ref(false)

const fetchTariffs = async () => {
  try {
    const response = await axios.get('https://admin.montelcompany.me/api/tariffs')
    const items = response.data
    tariffs.value = items
      .filter((t: Tariff) => t.group === '1')
      .map((item: Tariff) => ({
        text: item.Name,
        value: item.id
      }))
  } catch (error) {
    console.error('Error fetching tariffs:', error)
  }
}

const submitTariffChange = async () => {
  if (!selectedTariff.value) return
  
  try {
    // Здесь будет логика отправки запроса на смену тарифа
    console.log('Submitting tariff change:', selectedTariff.value)
  } catch (error) {
    console.error('Error submitting tariff change:', error)
  }
}

watch(() => userStore.state.value, async (state) => {
  if (state === 'authenticated') {
    await fetchTariffs()
  }
}, { immediate: true })

return (_ctx: any,_cache: any) => {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_subtitle = _resolveComponent("v-card-subtitle")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_switch = _resolveComponent("v-switch")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { cols: "12" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_card, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card_title, null, {
                    default: _withCtx(() => _cache[2] || (_cache[2] = [
                      _createTextVNode("Смена тарифа")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_v_card_subtitle, null, {
                    default: _withCtx(() => _cache[3] || (_cache[3] = [
                      _createTextVNode(" Вы можете подать заявку на смену тарифа ")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_v_card_text, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_select, {
                        modelValue: selectedTariff.value,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectedTariff).value = $event)),
                        items: tariffs.value,
                        "item-title": "text",
                        "item-value": "value",
                        label: "Выберите новый тариф",
                        variant: "outlined",
                        "return-object": ""
                      }, null, 8, ["modelValue", "items"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_card_actions, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_btn, {
                        color: "primary",
                        disabled: !selectedTariff.value,
                        onClick: submitTariffChange
                      }, {
                        default: _withCtx(() => _cache[4] || (_cache[4] = [
                          _createTextVNode(" Подать заявку ")
                        ])),
                        _: 1
                      }, 8, ["disabled"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, { cols: "12" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_card, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card_title, null, {
                    default: _withCtx(() => _cache[5] || (_cache[5] = [
                      _createTextVNode("Роуминг")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_v_card_subtitle, null, {
                    default: _withCtx(() => _cache[6] || (_cache[6] = [
                      _createTextVNode(" Управление роумингом ")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_v_card_text, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_switch, {
                        modelValue: roamingEnabled.value,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((roamingEnabled).value = $event)),
                        label: "Включить роуминг",
                        color: "primary"
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})