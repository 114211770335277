import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

import { ref, watch, computed } from 'vue'
import axios from 'axios'
import { useUserStore } from '@/stores/user'

interface Payment {
  id: number
  place: string
  amount: number
  datetime: string
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Payments',
  setup(__props) {

const userStore = useUserStore()
const userdata = ref<Payment[]>([])
const menu = ref(false)

// Инициализация даты как объекта Date
const date = ref(new Date())

// Ограничения для выбора даты
const maxDate = computed(() => new Date())

const minDate = computed(() => {
  const d = new Date()
  d.setFullYear(d.getFullYear() - 1)
  return d
})

// Форматированная дата для отображения
const dateFormatted = computed(() => {
  if (!date.value) return ''
  const year = date.value.getFullYear()
  const month = date.value.getMonth()
  const monthNames = [
    'Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь',
    'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'
  ]
  return `${monthNames[month]} ${year}`
})

const getPhoneNumber = () => {
  return userStore.state.context.user?.phoneNumber?.substring(4) || ''
}

const fetchPayments = async () => {
  if (userStore.state.value === 'authenticated') {
    const phoneNumber = getPhoneNumber()
    if (!phoneNumber) {
      console.error('Phone number is not available')
      return
    }

    try {
      // Форматируем дату в строку 'YYYY-MM-DD' или 'YYYY-MM' в зависимости от требований API
      const formattedDate = date.value.toISOString().slice(0, 7)
      const response = await axios.get(
        `https://admin.montelcompany.me/api/getuserinfo2paymants?number=${phoneNumber}&month=${formattedDate}`
      )
      userdata.value = response.data
    } catch (error) {
      console.error('Error fetching payments:', error)
    }
  }
}

const handleDateSelect = async (newDate: Date) => {
  menu.value = false
  await fetchPayments()
}

// Следим за состоянием аутентификации
watch(
  () => userStore.state.value,
  async (state) => {
    if (state === 'authenticated') {
      await fetchPayments()
    }
  },
  { immediate: true }
)

return (_ctx: any,_cache: any) => {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_date_picker = _resolveComponent("v-date-picker")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_table = _resolveComponent("v-table")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, { class: "payments-container" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, { dense: "" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { cols: "12" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_card, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, {
                    cols: "11",
                    sm: "5"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_menu, {
                        modelValue: menu.value,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((menu).value = $event)),
                        "close-on-content-click": false,
                        "min-width": "auto"
                      }, {
                        activator: _withCtx(({ props }) => [
                          _createVNode(_component_v_text_field, _mergeProps({
                            modelValue: dateFormatted.value,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((dateFormatted).value = $event)),
                            label: "Выберите месяц",
                            "prepend-icon": "mdi-calendar",
                            readonly: ""
                          }, props), null, 16, ["modelValue"])
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_v_date_picker, {
                            modelValue: date.value,
                            "onUpdate:modelValue": [
                              _cache[1] || (_cache[1] = ($event: any) => ((date).value = $event)),
                              handleDateSelect
                            ],
                            max: maxDate.value,
                            min: minDate.value,
                            views: ['month'],
                            view: "month"
                          }, null, 8, ["modelValue", "max", "min"])
                        ]),
                        _: 1
                      }, 8, ["modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_table, { density: "compact" }, {
                    default: _withCtx(() => [
                      _createElementVNode("tbody", null, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(userdata.value, (item) => {
                          return (_openBlock(), _createElementBlock("tr", {
                            key: item.id
                          }, [
                            _createElementVNode("td", null, _toDisplayString(item.place), 1),
                            _createElementVNode("td", null, _toDisplayString(item.amount / 100) + " €", 1),
                            _createElementVNode("td", null, _toDisplayString(item.datetime), 1)
                          ]))
                        }), 128))
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})