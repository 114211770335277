<template>
  <v-bottom-navigation
    v-model="activeItem"
    height="56"
    class="bottom-nav"
  >
    <v-btn
      v-for="item in menuItems"
      :key="item.value"
      :value="item.value"
      :to="item.to"
    >
      <v-icon>{{ item.icon }}</v-icon>
      <span>{{ item.title }}</span>
    </v-btn>
  </v-bottom-navigation>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import { useRoute } from 'vue-router'

const route = useRoute()
const activeItem = ref('overview')

const menuItems = [
  {
    value: 'overview',
    to: '/overview',
    icon: 'mdi-chart-box',
    title: 'Обзор'
  },
  {
    value: 'payments',
    to: '/payments',
    icon: 'mdi-cash',
    title: 'Платежи'
  },
  {
    value: 'services',
    to: '/services',
    icon: 'mdi-sim',
    title: 'Сервисы'
  }
]

// Синхронизируем активную вкладку с текущим маршрутом
watch(() => route.path, (newPath) => {
  const item = menuItems.find(item => item.to === newPath)
  if (item) {
    activeItem.value = item.value
  }
}, { immediate: true })
</script>

<style scoped>
.bottom-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: white;
}

:deep(.v-btn) {
  letter-spacing: normal !important;
  height: 100% !important;
}

:deep(.v-btn__content) {
  flex-direction: column;
  line-height: 1.2;
}

:deep(.v-icon) {
  margin-bottom: 4px;
}
</style>
