<template>
  <div class="sign-in-container">
    <div
      id="firebaseui-auth-container"
      ref="firebaseui-auth-container"
    />
  </div>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted } from 'vue'
import { authCompat } from "@/firebase"
import { authSettings } from "@/firebase/config"
import firebase from 'firebase/compat/app'
import * as firebaseui from "firebaseui"
import "firebaseui/dist/firebaseui.css"

let ui: firebaseui.auth.AuthUI | null = null

onMounted(() => {
  const uiConfig = {
    ...authSettings,
    signInOptions: [
      {
        provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
        defaultCountry: 'ME',
        whitelistedCountries: ['ME'],
        loginHint: '+382',
        recaptchaParameters: {
          size: 'normal',
          badge: 'bottomleft'
        }
      }
    ],
    callbacks: {
      signInSuccessWithAuthResult: (authResult: any) => {
        console.log('Auth result:', authResult)
        return false
      },
      signInFailure: (error: firebaseui.auth.AuthUIError) => {
        console.error('Auth error:', error)
      }
    }
  }

  ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(authCompat)
  ui.start("#firebaseui-auth-container", uiConfig)
})

onUnmounted(() => {
  if (ui) {
    ui.delete()
  }
})
</script>

<style scoped>
.sign-in-container {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
}

:deep(.firebaseui-container) {
  max-width: 100%;
}

:deep(.firebaseui-card-content) {
  padding: 16px;
}
</style>
