<template>
  <v-container>
    <!-- Диалог со способами оплаты -->
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="red">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Способы оплаты</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-col v-for="(location, index) in locations" :key="index">
          <v-card>
            <v-card-title class="text-h5">{{ location.city }}</v-card-title>
            <v-card-text>
              <div v-for="(address, i) in location.addresses" :key="i" class="text--primary">
                {{ address }}
              </div>
            </v-card-text>
            <v-card-actions v-if="location.mapUrl">
              <v-btn text :href="location.mapUrl">
                <v-icon left>mdi-map</v-icon>Карта
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-card>
    </v-dialog>

    <!-- Основной контент -->
    <v-row dense>
      <!-- Карточка баланса -->
      <v-col cols="12">
        <v-card color="green" dark>
          <v-card-title class="headline">
            Баланс {{ userdata?.balance }} €
          </v-card-title>
          <v-card-subtitle>
            {{ "+382 " + userdata?.phone }} <br />
            <div style="font-weight: bold">{{ userdata?.name }}</div>
          </v-card-subtitle>
          <v-card-actions>
            <v-btn text href="https://montelcompany.me/#map">
              {{ userdata?.tariffName }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

      <!-- Карточка расходов -->
      <v-col cols="12">
        <v-card>
          <v-card-title class="headline">Расходы</v-card-title>
          <v-card-subtitle>{{ getMonthName(userdata?.m) }}</v-card-subtitle>
          <v-table density="compact">
            <template v-slot:default>
              <tbody>
                <tr v-for="(item, key) in expenses" :key="key">
                  <td>{{ item.label }}</td>
                  <td>{{ userdata?.[item.field] }} €</td>
                </tr>
              </tbody>
            </template>
          </v-table>
          <v-card-actions>
            <v-row justify="center">
              <v-btn color="red" text @click="dialog = true">
                Способы оплаты
              </v-btn>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import axios from 'axios'
import { useUserStore } from '@/stores/user'

const userStore = useUserStore()
const dialog = ref(false)
const userdata = ref({})

// Данные о локациях
const locations = [
  {
    city: 'Budva',
    addresses: [
      'MixMarkt Ul. Zrtava Fasizma',
      'BŪREAU. Montenegro | HOT PROPERTY Budva bb'
    ],
    mapUrl: 'https://goo.gl/maps/3LdaKHXwfh5sMsDY9'
  },
  {
    city: 'Tivat',
    addresses: ['MixMarkt Jadranski put br.12, Markuševina, Tivat'],
    mapUrl: 'https://goo.gl/maps/UVrPrwAiQwZ2jgck9'
  },
  {
    city: 'Bar',
    addresses: [
      'MixMarkt Ul. Rista Lekića BB, Bar',
      'Autoskola Vozac Ul. Jovana Tomasevica, Bar'
    ],
    mapUrl: 'https://goo.gl/maps/4eLur6dS53qzocz9A'
  },
  {
    city: 'Herceg Novi',
    addresses: ['MixMarkt Mica Vavica br.1, Herceg Novi'],
    mapUrl: 'https://goo.gl/maps/PCMrABRBzWByayRr7'
  },
  {
    city: 'Podgorica',
    addresses: ['MixMarkt Stari aerodrom, Josipa Broza Tita, lamala A, Podgorica'],
    mapUrl: 'https://montelcompany.me/#map'
  }
]

// Структура расходов
const expenses = [
  { label: 'Абонентская плата ', field: 'amount' },
  { label: 'Звонки ', field: 'calls' },
  { label: 'SMS ', field: 'sms' },
  { label: 'Интернет ', field: 'gprs' },
  { label: 'Роуминг ', field: 'roaming' },
  { label: 'Платные сервисы ', field: 'services' }
]

// Получение названия месяца
const getMonthName = (month: string) => {
  if (!month) return ''
  const m = new Date(month)
  m.setMonth(m.getMonth())
  const sm = m.toLocaleString('ru-RU', { month: 'long' })
  return sm.charAt(0).toUpperCase() + sm.slice(1)
}

// Следим за состоянием аутентификации
watch(() => userStore.state.value, async (state) => {
  if (state === 'authenticated') {
    try {
      const response = await axios.get(
        `https://admin.montelcompany.me/api/getuserinfo2?number=${
          userStore.state?.context?.user?.phoneNumber?.substring(4)
        }`
      )
      userdata.value = response.data
    } catch (error) {
      console.error('Error fetching user data:', error)
    }
  }
}, { immediate: true })
</script>

<style scoped>
.v-card {
  margin-bottom: 16px;
}

.v-card-title {
  font-size: 1.25rem;
  font-weight: 500;
}

.v-card-text {
  font-size: 1rem;
}
</style>