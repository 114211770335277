import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'

// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

const app = createApp(App)
const pinia = createPinia()

// Инициализируем хранилище пользователя
import { useUserStore } from '@/stores/user'
app.use(pinia)
const userStore = useUserStore()
await userStore.init()

app.use(router)
app.use(vuetify)

app.mount('#app')
