<template>
  <v-container class="payments-container">
    <v-row dense>
      <v-col cols="12">
        <v-card>
          <v-col cols="11" sm="5">
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              min-width="auto"
            >
              <template v-slot:activator="{ props }">
                <v-text-field
                  v-model="dateFormatted"
                  label="Выберите месяц"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="props"
                />
              </template>
              <v-date-picker
                v-model="date"
                :max="maxDate"
                :min="minDate"
                @update:model-value="handleDateSelect"
                :views="['month']"
                view="month"
              ></v-date-picker>
            </v-menu>
          </v-col>
          
          <v-table density="compact">
            <tbody>
              <tr v-for="item in userdata" :key="item.id">
                <td>{{ item.place }}</td>
                <td>{{ item.amount / 100 }} €</td>
                <td>{{ item.datetime }}</td>
              </tr>
            </tbody>
          </v-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import { ref, watch, computed } from 'vue'
import axios from 'axios'
import { useUserStore } from '@/stores/user'

interface Payment {
  id: number
  place: string
  amount: number
  datetime: string
}

const userStore = useUserStore()
const userdata = ref<Payment[]>([])
const menu = ref(false)

// Инициализация даты как объекта Date
const date = ref(new Date())

// Ограничения для выбора даты
const maxDate = computed(() => new Date())

const minDate = computed(() => {
  const d = new Date()
  d.setFullYear(d.getFullYear() - 1)
  return d
})

// Форматированная дата для отображения
const dateFormatted = computed(() => {
  if (!date.value) return ''
  const year = date.value.getFullYear()
  const month = date.value.getMonth()
  const monthNames = [
    'Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь',
    'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'
  ]
  return `${monthNames[month]} ${year}`
})

const getPhoneNumber = () => {
  return userStore.state.context.user?.phoneNumber?.substring(4) || ''
}

const fetchPayments = async () => {
  if (userStore.state.value === 'authenticated') {
    const phoneNumber = getPhoneNumber()
    if (!phoneNumber) {
      console.error('Phone number is not available')
      return
    }

    try {
      // Форматируем дату в строку 'YYYY-MM-DD' или 'YYYY-MM' в зависимости от требований API
      const formattedDate = date.value.toISOString().slice(0, 7)
      const response = await axios.get(
        `https://admin.montelcompany.me/api/getuserinfo2paymants?number=${phoneNumber}&month=${formattedDate}`
      )
      userdata.value = response.data
    } catch (error) {
      console.error('Error fetching payments:', error)
    }
  }
}

const handleDateSelect = async (newDate: Date) => {
  menu.value = false
  await fetchPayments()
}

// Следим за состоянием аутентификации
watch(
  () => userStore.state.value,
  async (state) => {
    if (state === 'authenticated') {
      await fetchPayments()
    }
  },
  { immediate: true }
)
</script>

<style scoped>
.payments-container {
  padding-bottom: 56px !important;
  margin-bottom: 56px !important;
}

:deep(.v-text-field) {
  margin-bottom: 16px;
}

:deep(.v-card) {
  padding: 16px;
}

:deep(.v-table) {
  background: transparent;
}

:deep(.v-date-picker) {
  width: 100%;
  max-width: 290px;
}
</style>