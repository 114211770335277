import { createRouter, createWebHistory } from 'vue-router';
import { useUserStore } from '@/stores/user';
import Overview from '../views/Overview.vue';
import Payments from '../views/Payments.vue';
import MyServices from '../views/MyServices.vue';
import Home from "../views/Home.vue";

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: "Montel",
      public: true
    }
  },
  {
    path: '/overview',
    name: 'Overview',
    component: Overview,
    meta: {
      title: "Обзор"
    }
  },
  {
    path: '/payments',
    name: 'Payments',
    component: Payments,
    meta: {
      title: "Платежи"
    }
  },
  {
    path: '/services',
    name: 'MyServices',
    component: MyServices,
    meta: {
      title: "Сервисы"
    }
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

// Защита маршрутов
router.beforeEach((to, from, next) => {
  const userStore = useUserStore();
  const isAuthenticated = userStore.state.value === 'authenticated';
  const isPublicRoute = to.meta.public;

  if (!isAuthenticated && !isPublicRoute) {
    next('/');
  } else {
    next();
  }
});

export default router;
