<template>
  <div class="home-screen">
    <progress v-if="userStore.state.value === 'starting'" />
    <SignInForm v-show="userStore.state.value === 'no_user'" />
  </div>
</template>

<script setup lang="ts">
import { watch } from 'vue'
import { useRouter } from 'vue-router'
import { useUserStore } from '@/stores/user'
import SignInForm from "@/components/SignInForm.vue"

const router = useRouter()
const userStore = useUserStore()

watch(() => userStore.state.value, (state) => {
  if (state === 'authenticated') {
    router.push('/overview')
  }
}, { immediate: true })
</script>

<style lang="scss" scoped>
.home-screen {
  min-height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  progress {
    width: calc(100% - 72px);
    max-width: 460px;
  }

  .app-name {
    font-size: 1.2rem;
    position: absolute;
    width: 100%;
    bottom: 12px;
    display: block;
    text-align: center;
  }
}
</style>
