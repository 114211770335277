<template>
  <div>
    <v-navigation-drawer
      v-model="showMenu"
      temporary
    >
      <v-list>
        <v-list-item @click="handleLogout">
          <template v-slot:prepend>
            <v-icon>mdi-logout</v-icon>
          </template>
          <v-list-item-title>Выход</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      color="red"
    >
      <v-app-bar-nav-icon @click="toggleMenu" />
      <v-toolbar-title>{{ title }}</v-toolbar-title>
    </v-app-bar>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { useUserStore } from '@/stores/user'

const router = useRouter()
const showMenu = ref(false)
const title = ref('Overview')
const userStore = useUserStore()

const toggleMenu = () => {
  showMenu.value = !showMenu.value
}

const handleLogout = async () => {
  await userStore.signOut()
  showMenu.value = false
  router.push('/')
  // Перезагружаем страницу после небольшой задержки
  setTimeout(() => {
    window.location.reload()
  }, 100)
}
</script>

<style>
/* ... существующие стили ... */
</style>
