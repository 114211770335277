import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import { firebaseConfig } from './config'

// Инициализация для новой версии Firebase
const app = initializeApp(firebaseConfig)
export const auth = getAuth(app)

// Инициализация для firebaseui (compat версия)
firebase.initializeApp(firebaseConfig)
export const authCompat = firebase.auth() 