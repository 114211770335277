import { FirebaseOptions } from 'firebase/app'

export const firebaseConfig: FirebaseOptions = {
  apiKey: "AIzaSyCdKsi-CizE-k98GsnVKTPPlb21shyDE6M",
  authDomain: "user.montelcompany.me",
  databaseURL: "https://montel-ee69a.firebaseio.com",
  projectId: "montel-ee69a",
  storageBucket: "montel-ee69a.appspot.com",
  messagingSenderId: "142540632535",
  appId: "1:142540632535:web:6d37ef5f0f3ea99c6d9b92"
}

export const authSettings = {
  signInOptions: [
    {
      provider: 'phone',
      recaptchaParameters: {
        size: 'invisible',
        badge: 'bottomleft'
      },
      defaultCountry: 'ME',
      whitelistedCountries: ['ME'],
      loginHint: '+382'
    }
  ],
  signInFlow: 'popup',
  tosUrl: 'https://user.montelcompany.me',
  privacyPolicyUrl: 'https://user.montelcompany.me'
} 