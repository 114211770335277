import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { style: {"font-weight":"bold"} }

import { ref, watch } from 'vue'
import axios from 'axios'
import { useUserStore } from '@/stores/user'


export default /*@__PURE__*/_defineComponent({
  __name: 'Overview',
  setup(__props) {

const userStore = useUserStore()
const dialog = ref(false)
const userdata = ref({})

// Данные о локациях
const locations = [
  {
    city: 'Budva',
    addresses: [
      'MixMarkt Ul. Zrtava Fasizma',
      'BŪREAU. Montenegro | HOT PROPERTY Budva bb'
    ],
    mapUrl: 'https://goo.gl/maps/3LdaKHXwfh5sMsDY9'
  },
  {
    city: 'Tivat',
    addresses: ['MixMarkt Jadranski put br.12, Markuševina, Tivat'],
    mapUrl: 'https://goo.gl/maps/UVrPrwAiQwZ2jgck9'
  },
  {
    city: 'Bar',
    addresses: [
      'MixMarkt Ul. Rista Lekića BB, Bar',
      'Autoskola Vozac Ul. Jovana Tomasevica, Bar'
    ],
    mapUrl: 'https://goo.gl/maps/4eLur6dS53qzocz9A'
  },
  {
    city: 'Herceg Novi',
    addresses: ['MixMarkt Mica Vavica br.1, Herceg Novi'],
    mapUrl: 'https://goo.gl/maps/PCMrABRBzWByayRr7'
  },
  {
    city: 'Podgorica',
    addresses: ['MixMarkt Stari aerodrom, Josipa Broza Tita, lamala A, Podgorica'],
    mapUrl: 'https://montelcompany.me/#map'
  }
]

// Структура расходов
const expenses = [
  { label: 'Абонентская плата ', field: 'amount' },
  { label: 'Звонки ', field: 'calls' },
  { label: 'SMS ', field: 'sms' },
  { label: 'Интернет ', field: 'gprs' },
  { label: 'Роуминг ', field: 'roaming' },
  { label: 'Платные сервисы ', field: 'services' }
]

// Получение названия месяца
const getMonthName = (month: string) => {
  if (!month) return ''
  const m = new Date(month)
  m.setMonth(m.getMonth())
  const sm = m.toLocaleString('ru-RU', { month: 'long' })
  return sm.charAt(0).toUpperCase() + sm.slice(1)
}

// Следим за состоянием аутентификации
watch(() => userStore.state.value, async (state) => {
  if (state === 'authenticated') {
    try {
      const response = await axios.get(
        `https://admin.montelcompany.me/api/getuserinfo2?number=${
          userStore.state?.context?.user?.phoneNumber?.substring(4)
        }`
      )
      userdata.value = response.data
    } catch (error) {
      console.error('Error fetching user data:', error)
    }
  }
}, { immediate: true })

return (_ctx: any,_cache: any) => {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_card_subtitle = _resolveComponent("v-card-subtitle")!
  const _component_v_table = _resolveComponent("v-table")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_dialog, {
        modelValue: dialog.value,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((dialog).value = $event)),
        fullscreen: "",
        "hide-overlay": "",
        transition: "dialog-bottom-transition"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_toolbar, {
                dark: "",
                color: "red"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_btn, {
                    icon: "",
                    dark: "",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (dialog.value = false))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_icon, null, {
                        default: _withCtx(() => _cache[3] || (_cache[3] = [
                          _createTextVNode("mdi-close")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_toolbar_title, null, {
                    default: _withCtx(() => _cache[4] || (_cache[4] = [
                      _createTextVNode("Способы оплаты")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_v_spacer)
                ]),
                _: 1
              }),
              (_openBlock(), _createElementBlock(_Fragment, null, _renderList(locations, (location, index) => {
                return _createVNode(_component_v_col, { key: index }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_card_title, { class: "text-h5" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(location.city), 1)
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_v_card_text, null, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(location.addresses, (address, i) => {
                              return (_openBlock(), _createElementBlock("div", {
                                key: i,
                                class: "text--primary"
                              }, _toDisplayString(address), 1))
                            }), 128))
                          ]),
                          _: 2
                        }, 1024),
                        (location.mapUrl)
                          ? (_openBlock(), _createBlock(_component_v_card_actions, { key: 0 }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_btn, {
                                  text: "",
                                  href: location.mapUrl
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_icon, { left: "" }, {
                                      default: _withCtx(() => _cache[5] || (_cache[5] = [
                                        _createTextVNode("mdi-map")
                                      ])),
                                      _: 1
                                    }),
                                    _cache[6] || (_cache[6] = _createTextVNode("Карта "))
                                  ]),
                                  _: 2
                                }, 1032, ["href"])
                              ]),
                              _: 2
                            }, 1024))
                          : _createCommentVNode("", true)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024)
              }), 64))
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(_component_v_row, { dense: "" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { cols: "12" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_card, {
                color: "green",
                dark: ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card_title, { class: "headline" }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Баланс " + _toDisplayString(userdata.value?.balance) + " € ", 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_card_subtitle, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString("+382 " + userdata.value?.phone) + " ", 1),
                      _cache[7] || (_cache[7] = _createElementVNode("br", null, null, -1)),
                      _createElementVNode("div", _hoisted_1, _toDisplayString(userdata.value?.name), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_card_actions, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_btn, {
                        text: "",
                        href: "https://montelcompany.me/#map"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(userdata.value?.tariffName), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, { cols: "12" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_card, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card_title, { class: "headline" }, {
                    default: _withCtx(() => _cache[8] || (_cache[8] = [
                      _createTextVNode("Расходы")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_v_card_subtitle, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(getMonthName(userdata.value?.m)), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_table, { density: "compact" }, {
                    default: _withCtx(() => [
                      _createElementVNode("tbody", null, [
                        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(expenses, (item, key) => {
                          return _createElementVNode("tr", { key: key }, [
                            _createElementVNode("td", null, _toDisplayString(item.label), 1),
                            _createElementVNode("td", null, _toDisplayString(userdata.value?.[item.field]) + " €", 1)
                          ])
                        }), 64))
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_card_actions, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_row, { justify: "center" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_btn, {
                            color: "red",
                            text: "",
                            onClick: _cache[2] || (_cache[2] = ($event: any) => (dialog.value = true))
                          }, {
                            default: _withCtx(() => _cache[9] || (_cache[9] = [
                              _createTextVNode(" Способы оплаты ")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})