import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

import { ref, watch } from 'vue'
import { useRoute } from 'vue-router'


export default /*@__PURE__*/_defineComponent({
  __name: 'BottomNav',
  setup(__props) {

const route = useRoute()
const activeItem = ref('overview')

const menuItems = [
  {
    value: 'overview',
    to: '/overview',
    icon: 'mdi-chart-box',
    title: 'Обзор'
  },
  {
    value: 'payments',
    to: '/payments',
    icon: 'mdi-cash',
    title: 'Платежи'
  },
  {
    value: 'services',
    to: '/services',
    icon: 'mdi-sim',
    title: 'Сервисы'
  }
]

// Синхронизируем активную вкладку с текущим маршрутом
watch(() => route.path, (newPath) => {
  const item = menuItems.find(item => item.to === newPath)
  if (item) {
    activeItem.value = item.value
  }
}, { immediate: true })

return (_ctx: any,_cache: any) => {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_bottom_navigation = _resolveComponent("v-bottom-navigation")!

  return (_openBlock(), _createBlock(_component_v_bottom_navigation, {
    modelValue: activeItem.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((activeItem).value = $event)),
    height: "56",
    class: "bottom-nav"
  }, {
    default: _withCtx(() => [
      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(menuItems, (item) => {
        return _createVNode(_component_v_btn, {
          key: item.value,
          value: item.value,
          to: item.to
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_icon, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(item.icon), 1)
              ]),
              _: 2
            }, 1024),
            _createElementVNode("span", null, _toDisplayString(item.title), 1)
          ]),
          _: 2
        }, 1032, ["value", "to"])
      }), 64))
    ]),
    _: 1
  }, 8, ["modelValue"]))
}
}

})