import { defineStore } from 'pinia'
import { auth } from '@/firebase'
import { User } from 'firebase/auth'

interface UserState {
  user: User | null;
  state: {
    value: 'starting' | 'authenticated' | 'no_user';
    context: {
      user: User | null;
    };
  };
}

export const useUserStore = defineStore('user', {
  state: (): UserState => ({
    user: null,
    state: {
      value: 'starting',
      context: {
        user: null
      }
    }
  }),
  
  actions: {
    signOut() {
      auth.signOut()
    },
    
    async init() {
      auth.onAuthStateChanged(user => {
        if (user) {
          this.state.value = 'authenticated'
          this.state.context.user = user
        } else {
          this.state.value = 'no_user'
          this.state.context.user = null
        }
      })
    }
  }
}) 