import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { useUserStore } from '@/stores/user'


export default /*@__PURE__*/_defineComponent({
  __name: 'TopToolbar',
  setup(__props) {

const router = useRouter()
const showMenu = ref(false)
const title = ref('Overview')
const userStore = useUserStore()

const toggleMenu = () => {
  showMenu.value = !showMenu.value
}

const handleLogout = async () => {
  await userStore.signOut()
  showMenu.value = false
  router.push('/')
  // Перезагружаем страницу после небольшой задержки
  setTimeout(() => {
    window.location.reload()
  }, 100)
}

return (_ctx: any,_cache: any) => {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_navigation_drawer = _resolveComponent("v-navigation-drawer")!
  const _component_v_app_bar_nav_icon = _resolveComponent("v-app-bar-nav-icon")!
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_app_bar = _resolveComponent("v-app-bar")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_navigation_drawer, {
      modelValue: showMenu.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((showMenu).value = $event)),
      temporary: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_list, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_list_item, { onClick: handleLogout }, {
              prepend: _withCtx(() => [
                _createVNode(_component_v_icon, null, {
                  default: _withCtx(() => _cache[1] || (_cache[1] = [
                    _createTextVNode("mdi-logout")
                  ])),
                  _: 1
                })
              ]),
              default: _withCtx(() => [
                _createVNode(_component_v_list_item_title, null, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createTextVNode("Выход")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_v_app_bar, { color: "red" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_app_bar_nav_icon, { onClick: toggleMenu }),
        _createVNode(_component_v_toolbar_title, null, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(title.value), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}
}

})