import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx } from "vue"

import { computed } from 'vue'
import BottomNav from '@/components/BottomNav.vue'
import TopToolbar from '@/components/TopToolbar.vue'
import { useUserStore } from '@/stores/user'


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const userStore = useUserStore()
const isAuthenticated = computed(() => userStore.state.value === 'authenticated')

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, null, {
    default: _withCtx(() => [
      (isAuthenticated.value)
        ? (_openBlock(), _createBlock(TopToolbar, { key: 0 }))
        : _createCommentVNode("", true),
      _createVNode(_component_v_main, null, {
        default: _withCtx(() => [
          _createVNode(_component_router_view)
        ]),
        _: 1
      }),
      (isAuthenticated.value)
        ? (_openBlock(), _createBlock(BottomNav, { key: 1 }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})